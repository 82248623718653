<template>
  <div class="container">
    <div class="content">
      <!-- 面包屑 -->
      <div class="bread">
        <h5>当前位置：</h5>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">汇成国际</el-breadcrumb-item>
          <el-breadcrumb-item>快速入门</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="start">
        <img src="../assets/img/rm1.png" alt="" />
        <div class="rm2">
          <img src="../assets/img/rm2.png" alt="" />
          <button type="button" @click="$router.replace('/register')">
            立即注册
          </button>
        </div>
        <img src="../assets/img/rm3.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PointShop",
  data() {
    return {};
  },

  created() {},

  components: {},

  methods: {},

  computed: {},
};
</script>
<style lang='less' scoped>
.content {
  position: relative;
  width: 1260px;
  margin: 20px auto 148px;
}
.bread {
  display: flex;
  align-items: center;
  margin-bottom: 31px;
  padding-left: 30px;
  h5 {
    font-size: 18px;
    font-family: PingFang-SC-Bold;
    line-height: 24px;
    color: #000000;
  }
  span {
    font-weight: 400 !important;
    font-size: 16px;
    font-family: PingFangSC-Regular;
    color: #666666 !important;
  }
}
.bread /deep/ .is-link {
  font-weight: 400 !important;
  font-size: 16px;
  font-family: PingFangSC-Regular;
  color: #666666 !important;
}
.start {
  text-align: center;
  .rm2 {
    position: relative;
    margin: 110px 0 146px;
    button {
      margin-top: -8px;
      cursor: pointer;
      width: 148px;
      height: 57px;
      background: #1578ff;
      opacity: 1;
      border-radius: 8px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 30px;
      color: #ffffff;
    }
  }
  img {
    width: 100%;
  }
}
</style>